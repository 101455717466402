import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import { renderDynamicComponents } from "../components"
import { graphql } from "gatsby"

const ContentPage = ({ data: { allContentfulSiteSettings }, pageContext }) => {
  const { name, contentComponents, node_locale } = pageContext
  const allSiteSettings = allContentfulSiteSettings.edges
  const siteSettings = allSiteSettings.filter(item => item.node.node_locale === node_locale)[0].node
  return (
    <Layout siteSettings={siteSettings}>
      <SEO 
        title={`${siteSettings.siteName} - ${name}`} 
        description={siteSettings.siteDescription} 
        author={siteSettings.siteAuthor}
      />
      {contentComponents && renderDynamicComponents(contentComponents, node_locale)}
    </Layout>
  )
}

export const pageQuery = graphql`
  query siteSettings {
    allContentfulSiteSettings {
      edges {
          node {
              siteName
              siteDescription
              siteAuthor
              copyright
              navigation {
                  slug
                  name
              }
              logo {
                fixed(width: 200) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
              node_locale
          }
      }
    }
  }
`

export default ContentPage
